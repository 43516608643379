import React from 'react';
import { connect } from 'react-redux';
import { TabList, Button, SearchInput, DataTable, Pager, Dialog, ListSelector } from 'tyb';
import queryString from 'query-string'

const {
    initDataAllots,
    tagbatchAllots, domainAllots, productAllots, factoryAllots,
    fieldsAllots, dealerAllots, shopAllots, linksAllots, wechatAllots, prizeAllots, prizePoolAllots, prizeCardAllots, prizePointAllots
} = iceStarkData.store.get('commonAction')?.dataAllots;


import Distribution from './Components/Distribution'

import './index.scss';


@connect(
    state => ({
        loading: state.departments.loading,
        permissionIds: state.users.permissionIds,
        tagbatchAllotsList: state.dataAllots.tagbatchAllots,
        domainAllotsList: state.dataAllots.domainAllots,
        productAllotsList: state.dataAllots.productAllots,
        factoryAllotsList: state.dataAllots.factoryAllots,
        fieldsAllotsList: state.dataAllots.fieldsAllots,
        dealerAllotsList: state.dataAllots.dealerAllots,
        shopAllotsList: state.dataAllots.shopAllots,
        linksAllotsList: state.dataAllots.linksAllots,
        wechatAllotsList: state.dataAllots.wechatAllots,
        prizeAllotsList: state.dataAllots.prizeAllots,
        prizePoolAllotsList: state.dataAllots.prizePoolAllots,


        prizeCardAllotsList: state.dataAllots.prizeCardAllots,
        prizePointAllotsList: state.dataAllots.prizePointAllots,

    }),
    {
        initDataAllots,
        gettagbatchAllotsList: tagbatchAllots.REQUEST,
        getdomainAllotsList: domainAllots.REQUEST,
        getproductAllotsList: productAllots.REQUEST,
        getfactoryAllotsList: factoryAllots.REQUEST,
        getfieldsAllotsList: fieldsAllots.REQUEST,
        getdealerAllotsList: dealerAllots.REQUEST,
        getshopAllotsList: shopAllots.REQUEST,
        getlinksAllotsList: linksAllots.REQUEST,
        getwechatAllotsList: wechatAllots.REQUEST,
        getprizeAllotsList: prizeAllots.REQUEST,
        getprizePoolAllotsList: prizePoolAllots.REQUEST,


        getprizeCardAllotsList: prizeCardAllots.REQUEST,
        getprizePointAllotsList: prizePointAllots.REQUEST,

    }
)
export default class DataDistribution extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            placeholder: '码印刷规格',
            currentTab: '',
            selections: [],

            awardCurrentTab: '',
            awardList: [{
                text: '基础奖品',
                value: 'prize',
            },
             {
                text: '积分类型',
                value: 'prizePoint',
            }, {
                text: '卡片类型',
                value: 'prizeCard',
            }
        ],

        }
        this.params = { page: 0, size: 20 };
        this.tabList = [{
            text: '码印刷规格',
            value: 'tagbatch',
            permissionIds: 'code.tagConfig.allots'
        }, {
            text: '码域名',
            value: 'domain',
            permissionIds: ''
        }, {
            text: '产品信息',
            value: 'product',
            permissionIds: 'data.basicData.product.allots'
        }, {
            text: '工厂信息',
            value: 'factory',
            permissionIds: 'data.basicData.factory.allots'
        },

        // {
        //     text: '数据字典',
        //     value: 'fields',
        //     permissionIds: 'code.tagConfig.edit'
        // }, 
        {
            text: '经销商信息',
            value: 'dealer',
            permissionIds: 'data.basicData.dealer.allots'
        }, {
            text: '门店信息',
            value: 'shop',
            permissionIds: 'data.basicData.shop.allots'
        }, {
            text: '环节',
            value: 'links',
            permissionIds: ''
        }, {
            text: '微信商户号',
            value: 'wechat',
            permissionIds: 'data.we.allots'
        }, {
            text: '奖品信息',
            value: 'prize',
            permissionIds: 'promotion.prize.prize.allots'
        }, {
            text: '奖池信息',
            value: 'prizePool',
            permissionIds: 'promotion.prize.pool.allots'
        }];

        // 列表字段
        this.fields = {
            tagbatch: { text: '码印刷规格', value: [{ key: 'name', title: '码印刷规格名称' }, { key: 'codeSource.name', title: '码源名称' }, { key: 'deptName', title: '创建部门' }] },
            domain: { text: '码域名', value: [{ key: 'name', title: '域名名称' }, { key: 'domain', title: '域名' }, { key: 'deptName', title: '创建部门' }] },
            product: { text: '产品信息', value: [{ key: 'name', title: '产品名称' }, { key: 'deptName', title: '创建部门' },] },
            factory: { text: '工厂信息', value: [{ key: 'name', title: '工厂名称' }, { key: 'deptName', title: '创建部门' },] },
            fields: { text: '数据字典', value: [{ key: 'name', title: '字段名称' }, { key: 'deptName', title: '创建部门' },] },
            dealer: { text: '经销商信息', value: [{ key: 'name', title: '经销商名称' }, { key: 'deptName', title: '创建部门' },] },
            shop: { text: '门店信息', value: [{ key: 'name', title: '门店名称' }, { key: 'deptName', title: '创建部门' },] },
            links: { text: '环节字段', value: [{ key: 'name', title: '环节名称' }, { key: 'deptName', title: '创建部门' },] },
            wechat: { text: '微信商户号', value: [{ key: 'merchantId', title: '商户号' }, { key: 'appId', title: 'appId' }, { key: 'deptName', title: '创建部门' },] },
            prize: { text: '奖品信息', value: [{ key: 'name', title: '奖品名称' }, { key: 'deptName', title: '创建部门' },] },

            prizePoint: { text: '积分信息', value: [{ key: 'name', title: '积分类型名称' }, { key: 'deptName', title: '创建部门' },] },
            prizeCard: { text: '卡片信息', value: [{ key: 'name', title: '卡片类型名称' }, { key: 'deptName', title: '创建部门' },] },

            prizePool: { text: '奖池信息', value: [{ key: 'name', title: '奖池名称' }, { key: 'deptName', title: '创建部门' },] },
        }
    }

    componentDidMount() {
        const parsed = queryString.parse(this.props.history.location.search);
        const { id, name } = parsed;
        this.params.deptId = id;
        // 初始化数据
        this.props.initDataAllots();
        const { permissionIds } = this.props;
        const tabList = this.tabList.filter(val => {
            return !val.permissionIds || permissionIds.includes(val.permissionIds)
        })

        const currentTab = tabList[0].value;
        this.props[`get${currentTab}AllotsList`](this.params);
        this.setState({ id, name, tabList, currentTab })
    }

    componentDidUpdate(preProps) {
    }

    getColumns = (currentTab) => {
        if (!currentTab) return null
        return this.fields[currentTab].value.map(val => {
            const obj = {
                key: val.key,
                dataIndex: val.key,
                title: val.title,
            };
            switch (val.key) {
                case 'codeSource.name':
                    Object.assign(obj, { render: (value) => <div>{value || '标准码'}</div> })
                    break;
                case 'domain':
                    Object.assign(obj, {
                        render: (value, record) => {
                            if (record.codeSourceType == 'WECHART_CODE') {
                                return <div>{value}</div>
                            } else {
                                return <div>{record.protocol}{value}/{record.subDomain}</div>
                            }
                        }
                    })
                    break;
            }

            return obj;
        })
    }

    search = () => {
        this.setState({
            page: 0,
        });
        this.params.page = 0;
        this.state. currentTab == 'prize' ?
        this.props[`get${this.state.awardCurrentTab}AllotsList`](this.params):
        this.props[`get${this.state.currentTab}AllotsList`](this.params);
      
    }

    onPageChange = (val) => {
        this.setState({ page: val });
        this.params.page = val;
        this.state. currentTab == 'prize' ?
        this.props[`get${this.state.awardCurrentTab}AllotsList`](this.params):
        this.props[`get${this.state.currentTab}AllotsList`](this.params);
    }

    onPerPageChange = (val) => {
        this.setState({ perPageCount: val });
        this.params.size = val;
        this.params.page = 0;
        this.state. currentTab == 'prize' ?
        this.props[`get${this.state.awardCurrentTab}AllotsList`](this.params):
        this.props[`get${this.state.currentTab}AllotsList`](this.params);
    }

    // 切换 tab
    handleSwitchTab = (currentTab) => {
        if (currentTab === this.state.currentTab) return;


        if (currentTab == 'prize') {
            this.awardHandleSwitchTab('prize')
        }
        else {
            // 请求列表数据
            if (!this.props[`${currentTab}AllotsList`]) {
                this.props[`get${currentTab}AllotsList`](this.params);
            }
        }
        this.setState({ currentTab, placeholder: this.fields[currentTab].text });
    }

    // 分配数据
    handleOperate = (type) => {
        this.setState({ visible: !this.state.visible });
        if (type === 'refresh') {
            this.state. currentTab == 'prize' ?
            this.props[`get${this.state.awardCurrentTab}AllotsList`](this.params):
            this.props[`get${this.state.currentTab}AllotsList`](this.params);
        }
    }

    // 切换 tab
    awardHandleSwitchTab = (currentTab) => {
        if (currentTab === this.state.awardCurrentTab) return;
        //请求列表数据
        if (!this.props[`${currentTab}AllotsList`]) {
            this.props[`get${currentTab}AllotsList`](this.params);
        }
        this.setState({ awardCurrentTab: currentTab });
    }


    render() {
        const { word, page, perPageCount = 20, id, name, tabList, currentTab, placeholder, visible, record, awardList, awardCurrentTab } = this.state;
        const { permissionIds, loading } = this.props;
        const { content, totalElements } = currentTab == 'prize' ? this.props[`${awardCurrentTab}AllotsList`] || {} : this.props[`${currentTab}AllotsList`] || {};
        return (
            <div className="Data-Distribution">
                <div className="title">{name}</div>
                <TabList
                    value={currentTab}
                    list={tabList}
                    onChange={this.handleSwitchTab}
                />
                <div className="search-bar">
                    {permissionIds.includes('data.organization.department.add') && <Button onClick={this.handleOperate}>分配数据</Button>}
                    <div className="search-input-block">
                        <SearchInput
                            onChange={(val) => { this.params.word = val; this.setState({ word: val }) }}
                            onSearch={this.search}
                            value={word}
                            placeholder={`输入${placeholder}搜索`}
                        />
                    </div>
                </div>

                <div className={this.state.currentTab == 'prize' ? "main-block" : ""}>
                    {this.state.currentTab == 'prize' && <TabList
                        value={awardCurrentTab}
                        list={awardList}
                        onChange={this.awardHandleSwitchTab}
                    />}
                    <DataTable
                        columns={this.getColumns(currentTab == 'prize' ?awardCurrentTab:currentTab)}
                        data={content}
                        // rowKey="id"
                        loading={loading}
                    // rowClassName={(record) => record.status == 'DISABLE' ? 'table-gray-row' : ''}
                    />
                    {content && content.length > 0 && <div className="page-padding20"> <Pager
                        total={totalElements}
                        current={page}
                        perPageCountList={[10, 20, 50]}
                        perPageCount={perPageCount}
                        onPageChange={this.onPageChange}
                        onPerPageChange={this.onPerPageChange}
                    />
                    </div>}
                </div>
                <Distribution
                    visible={visible}
                    tab={currentTab == 'prize' ?awardCurrentTab:currentTab}
                    deptId={id}
                    handleVisible={this.handleOperate}
                />
            </div>
        )
    }
}
