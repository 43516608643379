import React from 'react';
import { connect } from 'react-redux';
import { Dialog, ListSelector } from 'tyb';
import { reduxForm } from 'redux-form';

const {
    tagbatchAllots, domainAllots, productAllots, factoryAllots,
    fieldsAllots, dealerAllots, shopAllots, linksAllots, wechatAllots, prizeAllots, prizePoolAllots, prizePointAllots, prizeCardAllots,
    tagbatchAllotsModify, domainAllotsModify, productAllotsModify, factoryAllotsModify,
    fieldsAllotsModify, dealerAllotsModify, shopAllotsModify, linksAllotsModify, wechatAllotsModify, prizeAllotsModify, prizePoolAllotsModify, prizePointAllotsModify, prizeCardAllotsModify
} = iceStarkData.store.get('commonAction')?.dataAllots;

import '../index.scss'

/**
 * 
 * @param {*} values 
 */
@connect(
    state => ({
        allotsModifyLoaded: state.dataAllots.allotsModifyLoaded,
        tagbatchAllotsSelector: state.dataAllots.tagbatchAllotsSelector,
        domainAllotsSelector: state.dataAllots.domainAllotsSelector,
        productAllotsSelector: state.dataAllots.productAllotsSelector,
        factoryAllotsSelector: state.dataAllots.factoryAllotsSelector,
        fieldsAllotsSelector: state.dataAllots.fieldsAllotsSelector,
        dealerAllotsSelector: state.dataAllots.dealerAllotsSelector,
        shopAllotsSelector: state.dataAllots.shopAllotsSelector,
        linksAllotsSelector: state.dataAllots.linksAllotsSelector,
        wechatAllotsSelector: state.dataAllots.wechatAllotsSelector,
        prizeAllotsSelector: state.dataAllots.prizeAllotsSelector,
        prizePoolAllotsSelector: state.dataAllots.prizePoolAllotsSelector,
        tagbatchAllotsById: state.dataAllots.tagbatchAllotsById,
        domainAllotsById: state.dataAllots.domainAllotsById,
        productAllotsById: state.dataAllots.productAllotsById,
        factoryAllotsById: state.dataAllots.factoryAllotsById,
        fieldsAllotsById: state.dataAllots.fieldsAllotsById,
        dealerAllotsById: state.dataAllots.dealerAllotsById,
        shopAllotsById: state.dataAllots.shopAllotsById,
        linksAllotsById: state.dataAllots.linksAllotsById,
        wechatAllotsById: state.dataAllots.wechatAllotsById,
        prizeAllotsById: state.dataAllots.prizeAllotsById,
        prizePoolAllotsById: state.dataAllots.prizePoolAllotsById,


        prizePointAllotsSelector: state.dataAllots.prizePointAllotsSelector,
        prizeCardAllotsSelector: state.dataAllots.prizeCardAllotsSelector,
        prizePointAllotsById: state.dataAllots.prizePointAllotsById,
        prizeCardAllotsById: state.dataAllots.prizeCardAllotsById,
    }),
    {
        gettagbatchAllotsList: tagbatchAllots.REQUEST,
        getdomainAllotsList: domainAllots.REQUEST,
        getproductAllotsList: productAllots.REQUEST,
        getfactoryAllotsList: factoryAllots.REQUEST,
        getfieldsAllotsList: fieldsAllots.REQUEST,
        getdealerAllotsList: dealerAllots.REQUEST,
        getshopAllotsList: shopAllots.REQUEST,
        getlinksAllotsList: linksAllots.REQUEST,
        getwechatAllotsList: wechatAllots.REQUEST,
        getprizeAllotsList: prizeAllots.REQUEST,
        getprizePoolAllotsList: prizePoolAllots.REQUEST,
        tagbatchAllotsModify: tagbatchAllotsModify.REQUEST,
        domainAllotsModify: domainAllotsModify.REQUEST,
        productAllotsModify: productAllotsModify.REQUEST,
        factoryAllotsModify: factoryAllotsModify.REQUEST,
        fieldsAllotsModify: fieldsAllotsModify.REQUEST,
        dealerAllotsModify: dealerAllotsModify.REQUEST,
        shopAllotsModify: shopAllotsModify.REQUEST,
        linksAllotsModify: linksAllotsModify.REQUEST,
        wechatAllotsModify: wechatAllotsModify.REQUEST,
        prizeAllotsModify: prizeAllotsModify.REQUEST,
        prizePoolAllotsModify: prizePoolAllotsModify.REQUEST,

        getprizePointAllotsList: prizePointAllots.REQUEST,
        getprizeCardAllotsList: prizeCardAllots.REQUEST,
        prizePointAllotsModify: prizePointAllotsModify.REQUEST,
        prizeCardAllotsModify: prizeCardAllotsModify.REQUEST,

    }
)
class Distribution extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: ''
        };
    }

    componentDidMount() {

    }

    componentDidUpdate(preProps) {
        // 查询模板已经分配的企业
        if (this.props.visible && this.props.visible !== preProps.visible) {
            const { tab, deptId } = this.props;
            if (!this.props[`${tab}AllotsSelector`]) {
                this.props[`get${tab}AllotsList`]({ isPage: false });
            }

            this.props[`get${tab}AllotsList`]({ deptId, isPage: false });
            this.setState({ data: '' })
        }

        if (this.props[`${this.props.tab}AllotsById`] !== preProps[`${this.props.tab}AllotsById`]) {
            this.setState({ data: this.props[`${this.props.tab}AllotsById`] })
        }

        // 刷新列表
        if ((/2\d{2}$/.test(this.props.allotsModifyLoaded) && this.props.allotsModifyLoaded !== preProps.allotsModifyLoaded)) {
            this.props.handleVisible('refresh');
        }
    }

    // 提交
    handleSubmit = () => {
        const { deptId, tab } = this.props;
        const { data } = this.state;
        this.props[`${tab}AllotsModify`]({ deptIds: [deptId], dataIds: data || [] });
    }

    render() {
        const { data } = this.state;
        const { visible, handleVisible, tab } = this.props;
        const list = this.props[`${tab}AllotsSelector`];

        return (
            <Dialog
                className="list-selector-dialog"
                title="分配数据"
                visible={visible}
                onCancel={handleVisible}
                onOk={this.handleSubmit}
            >
                <ListSelector
                    value={data}
                    list={list}
                    prepareItemTextFn={(item) => {
                        return <span key={item.value}>{item.text}</span>
                    }}
                    selectedItemTextFn={(item) => {
                        return <span>{item.text}</span>
                    }}
                    onChange={(data) => {
                        this.setState({ data });
                    }}
                />
            </Dialog >
        )
    }
}

export default reduxForm({
    form: 'DistributionForm',
    initialValues: {
    },
})(Distribution)


